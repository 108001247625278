const G18SpreadInfo = {
  sheetName: 'G02',
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'engineeringListName',
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'engineeringListName',
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '合同量',
      dataKey: 'num',
      width: '100'
    },
    {
      label: '本月计划',
      dataKey: 'currentMonthNum',
      width: '90'
    },
    {
      label: '本月完成',
      editable: true,
      dataKey: 'currentDateNum',
      validator (row) {
        if (row.currentDateNum !== undefined && row.currentDateNum !== null && row.currentDateNum !== '') {
          const len = row.unit.split('\\').length
          const currentDateNumList = row.currentDateNum.toString().split('\\')
          if (len !== currentDateNumList.length) {
            return false
          }
          if (!currentDateNumList.every(item => !isNaN(item))) {
            return false
          }
        }
        return true
      },
      width: '110'
    }
  ]
}

export default G18SpreadInfo
